import React from "react"

const About = () => (
  <>
    <h2>About</h2>

    <p>About the life</p>
  </>
)

export default About
